import { UserData } from "./types";

export const plansInfo = {
  free: {
    tier_name: "free",
    monthly_download_limit: 5,
    price_usd: "$0",
    price_inr: "₹0",
    generation_limit: 5,
    audio_uploads: 1,
    video_uploads: 1,
  },
  beatovenai_tier1: {
    tier_name: "beatovenai_tier1",
    monthly_download_limit: 15,
    users_limit: 2,
    monthly_price_usd: "$6",
    yearly_price_usd: "$30",
    monthly_price_inr: "₹299",
    yearly_price_inr: "₹1590",
    num_videos: "2+",
    audio_uploads: 3,
    video_uploads: 3,
  },
  beatovenai_tier2: {
    tier_name: "beatovenai_tier2",
    monthly_download_limit: 30,
    users_limit: 5,
    monthly_price_usd: "$10",
    yearly_price_usd: "$100",
    monthly_price_inr: "₹599",
    yearly_price_inr: "₹3190",
    num_videos: "5+",
    audio_uploads: 5,
    video_uploads: 5,
  },
  beatovenai_tier3: {
    tier_name: "beatovenai_tier3",
    monthly_download_limit: 60,
    users_limit: 15,
    monthly_price_usd: "$20",
    yearly_price_usd: "$200",
    monthly_price_inr: "₹999",
    yearly_price_inr: "₹7490",
    num_videos: "10+",
    audio_uploads: 10,
    video_uploads: 10,
  },
};

// show popup when user is in free tier
export function showUpgradePopup(userData: UserData) {
  return userData.tier_name === "free";
}

// show upgrade popup on stem download
export function showStemDownloadPopup(userData: UserData, downloadMode) {
  return (
    (userData.tier_name === "beatovenai_tier1" ||
      userData.tier_name === "free") &&
    downloadMode === "stems" &&
    (userData?.credits_remaining === undefined ||
      userData?.credits_remaining === null ||
      userData?.credits_remaining === 0)
  );
}

export function getDownloadLengthLimit(userData: UserData) {
  return userData.tier.features_scope.downloads.limits.download_duration;
}

// checks whether to enable downloading or not based on download limit and track length
export function isDownloadAllowed(trackDurationMs: number, userData: UserData) {
  const downloadLengthLimitSeconds = getDownloadLengthLimit(userData) as number;
  const userDownloadedLengthSeconds = userData.total_download_length_this_month;
  const trackDurationSeconds = trackDurationMs / 1000;
  let downloadSecondsLeft = 0;
  if (downloadLengthLimitSeconds) {
    downloadSecondsLeft =
      downloadLengthLimitSeconds - userDownloadedLengthSeconds;
  }
  if (userData.credits_remaining) {
    downloadSecondsLeft += userData.credits_remaining;
  }

  if (downloadSecondsLeft >= trackDurationSeconds) return true;
  return false;
}

export function stemDownloadEnabled(userData: UserData) {
  if (userData.tier.features_scope.stem_downloads?.enabled) {
    return true;
  } else {
    return false;
  }
}

export function isAppSumoUser(userData: UserData) {
  if (userData.appsumo_tier) {
    return true;
  } else {
    return false;
  }
}

export function hasUserCancelledSubscription(userData: UserData) {
  // we are currently checking for the absence of subscription_id to determine if user has canceled the subscription
  if (userData.subscription_id) {
    return false;
  }
  // subscription_id either doesn't exist(undefined) or is null
  return true;
}

export function getAudioProjectLimit(userData: UserData) {
  return (
    plansInfo[userData.tier_name].audio_uploads + getAudioVideoCredits(userData)
  );
}

export function getVideoProjectLimit(userData: UserData) {
  return (
    plansInfo[userData.tier_name].video_uploads + getAudioVideoCredits(userData)
  );
}

export function remainingDownloads(userData: UserData) {
  const usageLimit =
    plansInfo[userData.tier_name].monthly_download_limit * 60 +
    getCredits(userData);
  if (userData.total_download_length_this_month >= usageLimit) return 0;
  return usageLimit - userData.total_download_length_this_month;
}

export function remainingAudioUpload(userData: UserData) {
  const usageLimit =
    plansInfo[userData.tier_name].audio_uploads +
    getAudioVideoCredits(userData);
  if (userData.audio_projects_this_month >= usageLimit) return 0;
  return usageLimit - userData.audio_projects_this_month;
}

export function remainingVideoUpload(userData: UserData) {
  const usageLimit =
    plansInfo[userData.tier_name].video_uploads +
    getAudioVideoCredits(userData);
  if (userData.video_projects_this_month >= usageLimit) return 0;
  return usageLimit - userData.video_projects_this_month;
}

export function hasAudioUsageExceeded(userData: UserData) {
  const usageLimit =
    plansInfo[userData.tier_name].audio_uploads +
    getAudioVideoCredits(userData);
  if (userData.audio_projects_this_month >= usageLimit) return true;
  return false;
}

export function hasVideoUsageExceeded(userData: UserData) {
  const usageLimit =
    plansInfo[userData.tier_name].video_uploads +
    getAudioVideoCredits(userData);
  if (userData.video_projects_this_month >= usageLimit) return true;
  return false;
}

export async function getProjectsGenerated(): Promise<number> {
  const response = await fetch("/apis/tracks");
  const data = await response.json();
  return data.length;
}

export async function hasFreeGenerationLimitExceeded(): Promise<boolean> {
  const numProjects = await getProjectsGenerated();
  if (numProjects >= plansInfo["free"].generation_limit) return true;
  return false;
}

export function getAudioVideoCredits(userData: UserData) {
  const credits = getCredits(userData);
  return Math.ceil(credits / 60);
}

export function getCredits(userData: UserData) {
  if (userData.credits_remaining) {
    return userData.credits_remaining;
  } else {
    return 0;
  }
}

export function isCreditsPresent(userData: UserData) {
  return (
    typeof userData.credits_remaining !== "undefined" &&
    userData.credits_remaining > 0
  );
}
